<template>
  <b-overlay
    :show="show"
    variant="transparent"
    opacity="0.99"
    blur="5px"
    rounded="sm"
  >
    <template #overlay>
      <div class="text-center">
        <b-icon-controller
          font-scale="3"
          animation="cylon"
        />
        <p id="cancel-label">
          กรุณารอสักครู่...
        </p>
      </div>
    </template>
    <b-card no-body>
      <b-card-body>

        <div>
          <label for="example-datepicker">เลือกรอบวันที่</label>
          <b-form-datepicker
            id="example-datepicker"
            v-model="round"
            class="mb-1"
            @input="getwinloss()"
          />
        </div>

      </b-card-body>

      <div style="overflow-x:auto;">
        <table
          id="zero_config"
          class="table table-striped table-bordered"
        >
          <colgroup>
            <col width="20%">
            <!-- <col width="10%"> -->
            <!-- <col width="5%"> -->
            <col width="5%">
            <col width="5%">
            <col width="10%">
            <!-- <col width="10%"> -->
            <col width="10%">
            <!-- <col width="5%"> -->
            <!-- <col width="5%">
            <col width="5%"> -->
            <!-- <col width="10%"> -->
            <col width="10%">
          </colgroup>
          <thead>
            <tr class="text-center">
              <th class="align-middle">
                บัญชีเมมเบอร์
              </th>
              <!-- <th class="align-middle">
                ชื่อบัญชีเอเย่นต์
              </th> -->
              <!-- <th class="align-middle">
                จำนวนเล่ม
              </th> -->
              <th class="align-middle">
                จำนวนใบ
              </th>
              <th class="align-middle">
                ใบละ
              </th>
              <th class="align-middle">
                รวมราคา
              </th>
              <!-- <th class="align-middle">
                จำนวนที่ขายได้/ใบ
              </th> -->
              <th class="align-middle">
                win / lose
              </th>
              <!-- <th class="align-middle">
                คืน/ใบ
              </th>
              <th class="align-middle">
                ราคาคืน/ใบ
              </th> -->
              <!-- <th class="align-middle">
                เอเย่นต์
              </th> -->
              <th class="align-middle">
                บริษัท
              </th>
              <!-- <th class="align-middle">
                สรุปยอด
              </th> -->
            </tr>
          </thead>
          <tbody
            v-for="item in listdata"
            :key="item"
          >
            <tr>
              <td>{{ item.member_buy }}</td>
              <td>{{ item.bet }}</td>
              <td>{{ item.price }}</td>
              <!-- <td>{{ item.numleaves }}</td> -->
              <!-- <td>{{ item.each }}</td> -->
              <td>{{ Sumprice(item.price_sum) }}</td>
              <!-- <td>{{ item.soldamount }}</td> -->
              <td>{{ Commas(item.winlose) }}</td>
              <td>
                <span v-if="item.company < 0">
                  {{ Sumcom(item.company * -1) }}
                </span>
                <span v-else>
                  0
                </span>
              </td>
              <!-- <td>{{ item.refund }}</td> -->
              <!-- <td>{{ item.refundprice }}</td> -->
              <!-- <td>{{ Commas(item.sumrefund) }}</td> -->
              <!-- <td>{{ Commas(item.credit) }}</td> -->
              <!-- <td>
                <span>
                  {{ Commas(item.total) }}
                </span>
              </td> -->
            </tr>
          </tbody>
          <tfoot>
            <tr class="text-right font-weight-bold text-white">
              <td
                colspan="3"
                class="text-right font-weight-bolder"
              >
                สรุปรวม
              </td>
              <!-- <td>{{ Commas(sum) }}</td> -->
              <td>{{ Commas(sumprice) }}</td>
              <td>{{ Commas(sumwinlose) }}</td>
              <td>{{ Commas(sumcompany) }}</td>
              <!-- <td>-</td> -->
            </tr>
          </tfoot>
        </table>
      </div>

      <b-card-body class="d-flex justify-content-between flex-wrap pt-0" />
      <b-modal
        id="modal-1"
        title="Add Class"
        ok-only
        ok-title="Save"
      >
        <b-form-group
          label="Class"
          label-for="vi-first-name"
        >
          <b-input-group class="input-group-merge">
            <b-input-group-prepend is-text>
              <feather-icon icon="CheckSquareIcon" />
            </b-input-group-prepend>
            <b-form-input
              id="vi-first-name"
              placeholder=""
            />
          </b-input-group>
        </b-form-group>
      </b-modal>
    </b-card>
  </b-overlay>
</template>

<script>
import {
  BInputGroupPrepend, BModal, BCard,
  // BTable, BAvatar,
  BFormGroup,
  // BFormSelect, BPagination,
  BInputGroup, BFormInput,
  // BInputGroupAppend,
  // BButton,
  BFormDatepicker,
  BCardBody, VBToggle,
  BOverlay,
  BIconController,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
// import moment from 'moment-timezone'
// import flatPickr from 'vue-flatpickr-component'

export default {
  components: {
    BFormDatepicker,
    BInputGroupPrepend,
    BModal,
    BCard,
    // BTable,
    // BAvatar,
    BFormGroup,
    // BFormSelect,
    // BPagination,
    BInputGroup,
    BFormInput,
    // BInputGroupAppend,
    // BButton,
    BCardBody,
    // flatPickr,
    BOverlay,
    BIconController,
  },
  directives: {
    Ripple,
    'b-toggle': VBToggle,
  },
  data() {
    return {
      round: null,
      show: false,
      rangeDate: null,
      days: null,
      twinloss: 0.00,
      twaiting: 0.00,
      tdiscount: 0.00,
      twitingdiscount: 0.00,
      selected: [],
      options: [],
      listdata: [],
      perPage: 10,
      pageOptions: [10, 15, 20],
      totalRows: 1,
      currentPage: 1,
      sortBy: '',
      sortDesc: false,
      sortDirection: 'asc',
      filter: null,
      filterOn: [],
      usersList: [],
      datasidebar: [],
      infoModal: {
        id: 'info-modal',
        title: '',
        content: '',
      },
      fields: [
        { key: 'index', label: 'no.' },
        { key: 'username', label: 'ยูสเซอร์เนม' },
        // { key: 'appid', label: 'appid' },
        { key: 'winloss', label: 'win/loss' },
        { key: 'discount', label: 'ส่วนลด' },
        { key: 'waiting', label: 'รอผล' },
        { key: 'witingdiscount', label: 'ส่วนลดรอผล' },
        // { key: 'actions', label: 'Actions' },
      ],
      /* eslint-disable global-require */
      items: [],
      addNewDataSidebar: false,
      allSelected: false,
      indeterminate: false,
      start: null,
      end: null,
    }
  },
  computed: {
    sortOptions() {
      // Create an options list from our fields
      return this.fields
        .filter(f => f.sortable)
        .map(f => ({ text: f.label, value: f.key }))
    },
  },
  watch: {
    selected(newValue) {
      // Handle changes in individual flavour checkboxes
      if (newValue.length === 0) {
        this.indeterminate = false
        this.allSelected = false
      } else if (newValue.length === this.options.length) {
        this.indeterminate = false
        this.allSelected = true
      } else {
        this.indeterminate = true
        this.allSelected = false
      }
    },
  },
  mounted() {
    // Set the initial number of items
    this.totalRows = this.items.length
    // this.Get_userlist()
    // this.GetData()
    this.getwinloss()
    this.sum = 0
    this.sumprice = 0
    this.sumwinlose = 0
    this.sumcompany = 0
  },
  methods: {
    Sumprice(val) {
      this.sumprice += val
      return val
    },
    Sumcom(val) {
      this.sumcompany += val
      return val
    },
    CalSum(value) {
      if (value < 0) {
        this.sum += Math.abs(value)
      } else {
        this.sum -= value
      }
      return value
    },
    RangDateCal() {
      // console.log(date)
      const rangArray = this.rangeDate.split(' to ')
      const start = rangArray[0]
      const end = rangArray[1]

      this.start = start
      this.end = end
      // console.log(this.start, this.end)
      // eslint-disable-next-line no-bitwise
      if (this.start && this.end) {
        this.show = true
        // this.show = false
        const params = {
          // selected: this.selected,
          start: this.start,
          end: this.end,
        }
        this.$http
          .post('https://api.sabaideelotto.com/api/seamless/report/summary', params)
          .then(response => {
            this.listdata = response.data.data
            this.show = false
            // this.onFiltered(response.data)
          })
          .catch(error => {
            console.log(error)
            this.show = false
            this.listdata = []
          })
      }
    },
    wluser(username) {
      const paramss = {
        username,
        days: this.days,
        selected: this.selected,
      }
      this.$router.push('/win-loss-user')
      localStorage.setItem('wlParams', JSON.stringify(paramss))
    },
    toggleAll(checked) {
      this.selected = checked ? this.options.slice() : []
      this.getwinloss()
    },
    info(item, index, button) {
      this.infoModal.title = `Row index: ${index}`
      this.infoModal.content = JSON.stringify(item, null, 2)
      this.$root.$emit('bv::show::modal', this.infoModal.id, button)
    },
    resetInfoModal() {
      this.infoModal.title = ''
      this.infoModal.content = ''
    },
    // async GetData() {
    //   await this.$http
    //     .get('https://api.sabaideelotto.com/api/lottotype')
    //     .then(async response => {
    //       this.show = false
    //       // this.options = response.data
    //       // eslint-disable-next-line no-restricted-syntax
    //       for await (const da of response.data) {
    //         this.options.push(da.name)
    //       }
    //       // console.log(response.data)
    //     })
    //     .catch(error => console.log(error))
    // },
    async getwinloss() {
      this.show = true
      this.sum = 0
      this.sumprice = 0
      this.sumwinlose = 0
      this.sumcompany = 0
      this.listdata = []
      const params = {
        // selected: this.selected,
        round: this.round,
      }
      this.$http
        .post('https://api.sabaideelotto.com/api/seamless/report/summaryV2', params)
        .then(response => {
          this.sum = 0
          // this.listdata = response.data.data
          // eslint-disable-next-line no-restricted-syntax
          for (const items of response.data.data) {
            if (this.listdata.filter(item => item.member_buy === items.member_buy).length === 0) {
              if (items.status_result === 'lose') {
                // eslint-disable-next-line no-var, vars-on-top, block-scoped-var
                var win = response.data.price * -1
                // eslint-disable-next-line vars-on-top, no-var, block-scoped-var, no-mixed-operators
                var com = response.data.price * (100 - response.data.AgentData.percent) / 100
              } else {
                // eslint-disable-next-line no-var, vars-on-top, block-scoped-var, no-redeclare
                var win = Number(items.reward)
                // eslint-disable-next-line vars-on-top, no-var, no-redeclare, block-scoped-var
                var com = 0
              }
              const obj = {
                member_buy: items.member_buy,
                bet: 1,
                price: response.data.price,
                price_sum: response.data.price,
                AgentData: response.data.AgentData,
                // eslint-disable-next-line block-scoped-var
                winlose: win,
                // eslint-disable-next-line block-scoped-var
                company: com,
              }
              this.listdata.push(obj)
            } else {
              if (items.status_result === 'lose') {
                // eslint-disable-next-line no-var, vars-on-top, block-scoped-var, no-redeclare
                var win = response.data.price * -1
              } else {
                // eslint-disable-next-line no-var, vars-on-top, block-scoped-var, no-redeclare
                var win = Number(items.reward)
              }
              const change = this.listdata.filter(item => item.member_buy === items.member_buy)
              change[0].bet += 1
              change[0].price_sum += response.data.price
              // eslint-disable-next-line block-scoped-var
              change[0].winlose += win
              // eslint-disable-next-line block-scoped-var, no-mixed-operators
              change[0].company = change[0].winlose * (100 - response.data.AgentData.percent) / 100
            }
            // eslint-disable-next-line block-scoped-var
            this.sumwinlose += win
          }
          console.log(this.listdata)
          // this.twinloss = response.data.twinloss
          // this.twaiting = response.data.twaiting
          // this.tdiscount = response.data.tdiscount
          // this.twitingdiscount = response.data.twitingdiscount
          this.show = false
          // this.onFiltered(response.data)
        })
        .catch(error => {
          console.log(error)
          this.show = false
          this.listdata = []
          this.tbet = 0.00
          this.twaiting = 0.00
          this.tdiscount = 0.00
          this.twitingdiscount = 0.00
        })
    },
    onFiltered(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      // console.log(filteredItems)
      this.items = filteredItems.Mes
      this.totalRows = filteredItems.total
    },
    addData() {
      // this.sidebarData = JSON.parse(JSON.stringify(this.blankData))
      this.toggleDataSidebar(true)
      this.datasidebar = {}
    },
    editData(data) {
      // this.sidebarData = JSON.parse(JSON.stringify(this.blankData))
      this.toggleDataSidebar(true)
      this.datasidebar = data
    },
    toggleDataSidebar(val = false) {
      this.addNewDataSidebar = val
    },
    Commas(x) {
      if (!x) {
        return 0
      } if (x % 1 !== 0) {
        return Number(x).toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')
      }
      return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')
    },
  },
}
</script>

<style scoped>
table {
  width: 100%;
  border: 1px solid #ddd;
}

thead,
tfoot {
  background-color: rgb(255, 102, 0);
  color: rgb(0, 0, 0);
  text-align: center;
}

tbody {
  background-color: #f1e8e8;
}

tr:nth-child(even) {
  background-color: #f2f2f2;
}

td,
th {
  border: 1px solid rgb(190, 190, 190);
  padding: 5px 10px;
}

td {
  text-align: center;
  color: #000;
}
</style>

<style lang="scss" scoped>
@import "~@core/scss/base/pages/app-invoice.scss";
@import '@core/scss/vue/libs/vue-flatpicker.scss';

.form-item-section {
  background-color: $product-details-bg;
}
</style>
